<template>
    <div>
        <alert-message v-if="msg" :error="msg"></alert-message>
        <ListPage ref="appTable" :endpoint="`${apiCall}`" sortBy="+title" :headers="headers" title="Categories"
            :showActionsColumn="false" :showAddButton="isAllowed('createCategory')"
            :showEditButton="isAllowed('editCategory')" :showDeleteButton="isAllowed('deleteCategory')"
            :showViewButton="isAllowed('viewProduct')" @add="navigateTo('/app/categories/0')"
            @view="navigateTo(`/app/categories/view/${$event._id}`)" @edit="navigateTo(`/app/categories/${$event._id}`)"
            @delete="deleteCategory($event._id)">
            <!-- Header Section -->
            <template v-slot:extended-action-buttons>
                <import-file-button importData='categories' class="ml-2"></import-file-button>
            </template>
            <!-- Mobile View -->
            <template v-slot:item="{ item }" v-if="isMobile">
                <category-card :category="item" :icons="icons" @delete="removeItem"></category-card>
                <v-divider></v-divider>
            </template>
            <!-- Web / Tablet View -->
            <template v-slot:image="{ value }" v-if="isTabletAndUp">
                <v-img v-if="value" :src="imagePath(value.path)" height="64px" width="64px"></v-img>
            </template>
            <template v-slot:active="{ value }" v-if="isTabletAndUp">
                <v-chip small label :class="value ? 'status-active' : 'status-disabled'">{{ value ? 'Active' :
                    'Disabled' }}</v-chip>
            </template>
        </ListPage>
        <alert-message v-if="msg" :error="msg"></alert-message>
    </div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import CategoryCard from '@/components/CategoryResponsiveCard'
import ImportFileButton from '@/components/ImportFileButton'
import ListPage from '../../../components/common/ListPage.vue';
export default {
    components: {
        ActionButton,
        CategoryCard,
        ImportFileButton,
        ListPage
    },
    data() {
        return {
            search: '',
            headers: [{
                text: 'Image',
                value: 'image'
            },
            {
                text: 'Title',
                value: 'title'
            },
            {
                text: "Active",
                value: 'active'
            },
            {
                text: 'Display Order',
                value: 'displayOrder',
                align: 'right'
            },
            {
                text: 'Actions',
                value: 'action',
                align: 'right'
            }
            ],
            search: '',
            items: [],
            icons: [{
                name: "mdi-eye",
                path: "/app/categories/view/",
                permission: "viewBrand"
            }, {
                name: "mdi-pencil",
                path: "/app/categories/",
                permission: "editCategory"
            },
            {
                name: "mdi-delete",
                permission: "deleteCategory"
            }
            ],
            apiCall: appConstants.CATEGORIES_API
        }
    },
    methods: {
        async deleteCategory(id) {
            try {
                await this.deleteItem("Are you sure you want to delete?", appConstants.CATEGORIES_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
                this.$refs.appTable.loadData()
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        removeItem(id) {
            this.deleteCategory(id)
        }
    },
}
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>>
